import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import ScheduleBox from "./ScheduleBox";
import { useDispatch } from "react-redux";
import { AppDispatch, getNotarySessions } from "../../store";
import { API_STATUS } from "../../utils";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CreateSessionPlusIcon } from "../../assets/plus-sm-green.svg";

const SessionTimeline: React.FC<{
  onSessionSelect: (session: any) => void;
  activeSessionId?: string;
}> = ({ onSessionSelect, activeSessionId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isMounted = useRef(true);
  const [isSessionLoading, setIsSessionLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
  const [sessions, setSessions] = useState<any>([]);

  const handleBoxClick = (sessionId: string) => {
    setSelectedSessionId(sessionId);
    const selectedSession = sessions.find((session: any) => session.id == sessionId);
    if (selectedSession) {
      onSessionSelect(selectedSession);
    }
  };

  const fetchSessions = useCallback(
    debounce(async (showLoading = true) => {
      try {
        if (showLoading && isFirstLoad) {
          setIsSessionLoading(true);
        }

        const fetchBothSessions = async () => {
          const notarySessions: any = await dispatch(getNotarySessions({}));
          if (notarySessions.meta.requestStatus === API_STATUS.fulfilled) {
            let filteredNotarySessions = notarySessions.payload?.items?.filter(
              (item: any) => item?.isConfirmed === "1"
            );
            const sorted = filteredNotarySessions?.sort((a: any, b: any) => {
              const dateA = new Date(a.requestedDatetime).getTime();
              const dateB = new Date(b.requestedDatetime).getTime();
              return dateA - dateB;
            });

            setSessions([...sorted]);

            if (isFirstLoad || !selectedSessionId) {
              const activeSession = activeSessionId
                ? sorted.find((session: any) => session.id.toString() === activeSessionId)
                : sorted[0];

              if (activeSession) {
                setSelectedSessionId(activeSession.id.toString());
                onSessionSelect(activeSession);
              }
            }
          }
        };

        await fetchBothSessions();
      } catch (error) {
        console.error("Error in get session API:", error);
      } finally {
        if (isMounted.current && showLoading) {
          setIsSessionLoading(false);
          if (isFirstLoad) {
            setIsFirstLoad(false);
          }
        }
      }
    }, 1500),
    [dispatch, activeSessionId, selectedSessionId, isFirstLoad, onSessionSelect]
  );


  useEffect(() => {
    isMounted.current = true;
    fetchSessions();
    return () => {
      isMounted.current = false;
      fetchSessions.cancel();
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => fetchSessions(false), 2000);

    return () => clearInterval(intervalId);
  }, [fetchSessions]);

  const handleCreateSession = () => {
    navigate("/createSession", { state: { isSessionRequest: true } });
  };

  return (
    <SessionInfoBox>
      <CreateSessionButton onClick={handleCreateSession}>
        Create Request <CreateSessionPlusIcon />
      </CreateSessionButton>
      <ScheduleBoxContainer isLoading={isSessionLoading}>
        {isSessionLoading && isFirstLoad ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : sessions.length === 0 ? (
          <NoItemsFound>No items found</NoItemsFound>
        ) : (
          sessions?.map((item: any, index: number) => (
            <ScheduleBox
              key={item.id}
              isSelected={selectedSessionId === item.id.toString()}
              onClick={() => handleBoxClick(item.id.toString())}
              item={item}
            />
          ))
        )}
      </ScheduleBoxContainer>
    </SessionInfoBox>
  );
};

export default SessionTimeline;

const NoItemsFound = styled.div`
  text-align: center;
  margin-top: 18px;
  font-size: 16px;
  color: #888;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
`;

const ScheduleBoxContainer = styled.div<{ isLoading: boolean }>`
  padding: 7px;
  display: ${(props) => (props.isLoading ? "flex" : "block")};
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow-y: auto;
  flex: 1;
  cursor: pointer;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SessionInfoBox = styled.div`
  width: 96%;
  border-radius: 9px;
  background-color: #f9f9f9;
  padding: 18px 9px;
  display: flex;
  flex-direction: column;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b;
  width: 22px;
  height: 22px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CreateSessionButton = styled.button`
  width: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 9px;
  color: #64BD8B;
  background-color: white;
  border: 2px solid #64BD8B;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  margin: 18px auto 0;
`;
