import React from "react";
import styled from "styled-components";
import moment from "moment";

interface ScheduleBoxProps {
  isSelected: boolean;
  item: any;
  onClick: () => void;
}

const ScheduleBox: React.FC<ScheduleBoxProps> = ({
  isSelected,
  onClick,
  item,
}) => {
  const initialDate = new Date(item?.requestedDatetime);

  const currentTime = Date.now();
  const scheduleTime = initialDate.getTime();
  const timeDifferenceInMinutes = (scheduleTime - currentTime) / (1000 * 60);
  
  const isWithinNinetyMinutes = timeDifferenceInMinutes < 90 && timeDifferenceInMinutes > 0;
  
  return (
    <Container isselected={isSelected} onClick={onClick}>
      <Header>
        <LeftText>{item?.name}</LeftText>
        {/* {item?.isViewed && (
        <RightText>Viewed</RightText>
        )} */}
      </Header>
      <NotaryOwner>
        <ScheduleText isWithinNinetyMinutes={isWithinNinetyMinutes}>
          Scheduled:
        </ScheduleText>{" "}
        {moment(initialDate).format("M/D/YY h:mma")}
      </NotaryOwner>
      <InfoRow>
        <InfoBlock>
         {/* <Label>Appt. ID</Label> */}
          <Value>{item?.appointmentId}</Value>
        </InfoBlock>
        <InfoBlock>
          {/* <Label>Notary</Label> */}
          <Value>{item?.officialName}</Value>
        </InfoBlock>
      </InfoRow>
    </Container>
  );
};

export default ScheduleBox;


export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  margin-top: 7.2px; /* Reduced from 8px */
  padding: 12.6px; /* Reduced from 14px */
  border-radius: 7.2px; /* Reduced from 8px */
`;

export const Container = styled.div<{
  isselected: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.isselected ? "0.9px solid blue" : "none")}; /* Reduced from 1px */
  padding: 14.4px; /* Reduced from 16px */
  max-height: 360px; /* Reduced from 400px */
  overflow-y: auto;
  margin-bottom: 18px; /* Reduced from 20px */
  cursor: pointer;
  background: ${(props) =>
    props.isselected ? props.theme.colors.light : "white"};
  border-radius: 9px; /* Reduced from 10px */
  &:last-child {
    border-bottom: none;
  }

  /* Adding box-shadow when selected */
  box-shadow: rgba(50, 50, 93, 0.225) 0px 5.4px 10.8px -1.8px,
    rgba(0, 0, 0, 0.27) 0px 2.7px 6.3px -2.7px; /* Reduced shadow intensities */

  /* Hover effect */
  &:hover {
    background: ${(props) => (props.isselected ? "none" : "#EBEBEB")};

    ${InfoRow} {
      background-color: white;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16.2px; /* Reduced from 18px */
`;

export const LeftText = styled.div`
  color: #333344;
  display: flex;
  align-items: center;
  gap: 9px; /* Reduced from 10px */
  font-weight: 450; /* Reduced from 500 */
  max-width: 157.5px; /* Reduced from 175px */
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const RightText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12.6px; /* Reduced from 14px */
  color: ${(props) => props.theme.colors.primary};
  &::before {
    content: "";
    display: inline-block;
    width: 7.2px; /* Reduced from 8px */
    height: 7.2px; /* Reduced from 8px */
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-right: 4.5px; /* Reduced from 5px */
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7.2px; /* Reduced from 8px */
`;

export const Label = styled.span`
  color: #555;
  font-size: 12.6px; /* Reduced from 14px */
`;

export const NotaryOwner = styled.span`
  font-size: 14.4px; /* Reduced from 16px */
  margin-top: 5.4px; /* Reduced from 6px */
  margin-bottom: 2.7px; /* Reduced from 3px */
`;

export const ScheduleText = styled.span<{ isWithinNinetyMinutes: boolean }>`
  font-size: 14.4px; /* Reduced from 16px */
  margin-top: 5.4px; /* Reduced from 6px */
  margin-bottom: 5.4px; /* Reduced from 6px */
  color: ${(props) => (props.isWithinNinetyMinutes ? "#EA4125" : "black")};
`;

export const Value = styled.span`
  font-size: 14.4px; /* Reduced from 16px */
`;
