export function dateFormatVal(
  dateData: { _nanoseconds: number; _seconds: number } | string | Date
): { time: string; date: string } {
  let date: Date;

  if (typeof dateData === "object" && "_seconds" in dateData) {
    date = new Date(dateData._seconds * 1000);
  } else if (typeof dateData === "string" || dateData instanceof Date) {
    date = new Date(dateData);
  } else {
    return {
      date: "",
      time: "",
    };
  }

  if (isNaN(date.getTime())) {
    return {
      date: "",
      time: "",
    };
  }

  const datePart = new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(date);

  const timePart = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
    .format(date)
    .toUpperCase();

  const formattedDate = `${datePart} ${timePart}`;

  return {
    date: datePart,
    time: timePart,
  };
}

export const parseErrorMessage = (error: string): string => {
  const parts = error.split(":");
  if (parts.length > 1) {
    return parts[parts.length - 1].trim();
  }
  return error;
};

export const getUnixTimestamp = (date: string, time: string): any => {
  const dateTimeString = `${date}T${time}:00`;

  const dateObject = new Date(dateTimeString);

  const unixTimestamp = Math.floor(dateObject.getTime() / 1000);

  return unixTimestamp;
};

export const removeBase64Header = function (input: any) {
  for (var i = 0; i < input.length; i++) {
    if (input[i] === ",") {
      return input.substr(i + 1);
    }
  }

  return input;
};

export function toCamelCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const resizeSignature = function (base64: string, params: any) {
  var width = (params && params.width) || 500;
  var height = (params && params.height) || 500;
  var format = (params && params.format) || "image/png";
  var quality = (params && params.quality) || 1;
  var img = document.createElement("img");
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");

  format = format || "image/png";
  quality = quality || 1;

  img.src = base64;

  return new Promise(function (resolve, reject) {
    img.onload = function () {
      if (ctx) ctx.drawImage(img, 0, 0);

      var newWidth = img.width;
      var newHeight = img.height;

      if (newWidth > newHeight) {
        if (newWidth > width) {
          newHeight *= width / newWidth;
          newWidth = width;
        }
      } else if (newHeight > height) {
        newWidth *= height / newHeight;
        newHeight = height;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx = canvas.getContext("2d");
      if (ctx) ctx.drawImage(img, 0, 0, newWidth, newHeight);

      return resolve(canvas.toDataURL(format, quality));
    };

    img.onerror = reject;
  });
};

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const notaryLink = process.env.REACT_APP_NOTARY_URL;

export let scheduledAtSortFn = function (a: any, b: any) {
  if (a?.scheduledAt._seconds > b?.scheduledAt._seconds) {
    return 1;
  }

  if (a?.scheduledAt._seconds < b?.scheduledAt._seconds) {
    return -1;
  }

  return 0;
};

export let finishedAtSortFn = function (a: any, b: any) {
  if (!a?.finishedAt) {
    return -1;
  }

  if (!b?.finishedAt) {
    return 1;
  }

  if (a?.finishedAt?._seconds > b?.finishedAt._seconds) {
    return -1;
  }

  if (a?.finishedAt._seconds < b?.finishedAt._seconds) {
    return 1;
  }

  return 0;
};
