import React, { useState } from "react";
import styled from "styled-components";
import { AppDispatch, sendDocumentRequest } from "../../store";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

interface RequestDocumentPopupProps {
  onClose: () => void;
  onSuccess: () => void;
  session: any;
}

const RequestDocumentPopup: React.FC<RequestDocumentPopupProps> = ({
  onClose,
  onSuccess,
  session,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [instructions, setInstructions] = useState("");
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleSendClick = async () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setIsRequestLoading(true);

    const response = await dispatch(
      sendDocumentRequest({
        email,
        instructions,
        officialEmail: session?.official?.name,
        appointmentId: session?.appointmentId,
      })
    );

    if (response.meta.requestStatus === "fulfilled") {
      onClose();
      onSuccess();
      setIsRequestLoading(false);
    } else {
      onClose();
      toast.error("Please try again");
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailError) {
      setEmailError("");
    }
  };

  return (
    <>
      <PopupOverlay onClick={onClose}>
        <PopupContainer onClick={(e) => e.stopPropagation()}>
          <PopupHeader>Request Files</PopupHeader>
          <Form>
            <Label>Email:</Label>
            <EmailBox>
              <Input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email address"
              />
              {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
            </EmailBox>
            <Label>Instructions (optional):</Label>
            <Textarea
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="Enter any instructions"
            />
            <ButtonGroup>
              <Button onClick={onClose} variant="cancel">
                Close
              </Button>
              <Button onClick={handleSendClick} variant="save">
                {isRequestLoading ? <LoadingSpinner /> : "Save"}
              </Button>
            </ButtonGroup>
          </Form>
        </PopupContainer>
      </PopupOverlay>
    </>
  );
};

export default RequestDocumentPopup;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopupHeader = styled.h2`
  margin-bottom: 20px;
`;

const EmailBox = styled.div`
  margin-bottom: 20px;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: -webkit-fill-available;
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 60px;
  resize: none;
  margin-bottom: 20px;
  font-family: "Inter",sans-serif;

`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
