import React, { useEffect, useState, useRef, useCallback } from "react";
import ScheduleBox from "./ScheduleBox";
import { GrSearch } from "react-icons/gr";
import { FaFilter, FaSpinner } from "react-icons/fa";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppDispatch, getSessions, getSessionsByAutoSearch, markSessionAsViewed, sessionBatch } from "../../store";
import debounce from "lodash.debounce";
import DateInputWithIcon from "./DateInput";
import { ReactComponent as DownIcon } from "../../assets/down-icon.svg";
import { ReactComponent as DropdownIcon } from "../../assets/chevron-down.svg";
import { finishedAtSortFn, scheduledAtSortFn, toCamelCase } from "../../utils/helper";

interface Session {
  id: string;
  title: string;
  date: string;
  name: string;
  appointmentId: string;
}

interface Sessions {
  scheduled: Session[];
  completed: Session[];
}

export interface NextPageCursor {
  scheduled: string;
  completed: string;
}

const SessionTimeline: React.FC<{
  onSessionSelect: (session: any) => void;
  activeSessionId?: string;
  onTabChange: (activeTab: "scheduled" | "completed") => void;
}> = ({ onSessionSelect, activeSessionId, onTabChange }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isMounted = useRef(true);
  const [nextPageCursor, setNextPageCursor] = useState<NextPageCursor>({
    scheduled: "",
    completed: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState<string>("name");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);

  const activeSessionIdRef = useRef(activeSessionId);
  const suggestionBoxRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  const [activeTab, setActiveTab] = useState<"scheduled" | "completed">(
    "scheduled"
  );
  const handleTabChange = (tab: "scheduled" | "completed") => {
    setSearchTerm("");
    setShowFilters(false);
    setShowSuggestions(false)
    setActiveTab(tab);
    onTabChange(tab);
  };

  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [sessions, setSessions] = useState<Sessions>({
    scheduled: [],
    completed: [],
  });
  const [batchSessions, setBatchSessions] = useState<Sessions>({
    scheduled: [],
    completed: [],
  });
  const [searchSessions, setSearchSessions] = useState<Sessions>({
    scheduled: [],
    completed: [],
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const toggleFilterDropdown = () => setShowFilters((prev) => !prev);
  const sessionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideDropdown = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };



  const handleBoxClick = async (item: any) => {
    setShowSuggestions(false);
    const sessionMarkedAsViewed = dispatch(
      markSessionAsViewed({
        sessionId: item?.id
      })
    );


    // if (intervalId.current) {
    //   clearInterval(intervalId.current);
    //   intervalId.current = null;
    // }
    const activeSession = sessions[activeTab].find(
      (session: any) => session.id.toString() === item.id
    );
    if (activeSession) {
      sessionRefs.current[item.id]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      onSessionSelect(activeSession);
      setSelectedBox(sessions[activeTab].indexOf(activeSession));
    }
    // if (searchSessions[activeTab].length > 0) {
    //   console.log("selected item", item)
    //   let index = sessions[activeTab].indexOf(item);
    //   setSelectedBox(index);
    //   onSessionSelect(item);
    // }
  };



  const handleDropdownToggle = () => setShowDropdown((prev) => !prev);
  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setShowDropdown(false);
  };

  const fetchSessions = useCallback(
    debounce(
      async (
        search: string,
        tab: "scheduled" | "completed",
        selectedOption: string,
        sessions: Sessions,
        nextPageCursor?: NextPageCursor
      ) => {
        try {
          setIsLoading(true);

          if (search) {
            const response = await dispatch(
              getSessionsByAutoSearch({
                type:
                  selectedOption === "Appt ID"
                    ? "appointmentId"
                    : selectedOption === "notary"
                      ? "official"
                      : selectedOption,
                search,
                status: tab === "scheduled" ? "active" : "completed",
              })
            );

            if (response.meta.requestStatus === "fulfilled") {
              setSessions((prev) => ({
                ...prev,
                [tab]: response.payload,
              }));
            }
          } else {
            if (nextPageCursor?.[tab]) {
              const loadMoreResponse = await dispatch(
                getSessions({
                  status: tab,
                  search: "",
                  cursor: nextPageCursor[tab],
                })
              );

              if (loadMoreResponse.meta.requestStatus === "fulfilled") {
                const newSessions = loadMoreResponse.payload?.data || [];
                const updatedNextCursor = loadMoreResponse.payload?.nextPageCursor;

                setSessions((prev) => ({
                  ...prev,
                  [tab]: [...prev[tab], ...newSessions], // Append new sessions to the current list
                }));

                setNextPageCursor((prev) => ({
                  ...prev,
                  [tab]: updatedNextCursor || "",
                }));
              }
            } else {
              const [scheduledResponse, completedResponse] = await Promise.all([
                dispatch(getSessions({ status: "scheduled", search: "" })),
                dispatch(getSessions({ status: "completed", search: "" })),
              ]);
              let foundSessions = {
                ...sessions,
              }
              let tempNextPageCursor: NextPageCursor = {
                completed: "",
                scheduled: ""
              };

              if (scheduledResponse.meta.requestStatus === "fulfilled") {
                const responseData = [...(scheduledResponse.payload?.data || [])];
                const reverseResponse = responseData.reverse();
                tempNextPageCursor = {
                  ...tempNextPageCursor,
                  scheduled: reverseResponse[reverseResponse?.length - 1]?.nextPageCursor
                };
                const tempScheduled = [...reverseResponse].sort(scheduledAtSortFn);

                foundSessions = {
                  ...foundSessions,
                  scheduled: [...(scheduledResponse.payload?.data || [])]
                };
                setSessions((prev: any) => ({
                  ...prev,
                  scheduled: tempScheduled,
                }));
              }

              if (completedResponse.meta.requestStatus === "fulfilled") {
                const responseData = [...(completedResponse.payload?.data || [])];
                const reverseResponse = responseData.reverse();
                const tempCompleted = [...reverseResponse].sort(finishedAtSortFn);
                tempNextPageCursor = { ...tempNextPageCursor, completed: reverseResponse[reverseResponse.length - 1].nextPageCursor }
                setNextPageCursor({
                  ...nextPageCursor,
                  ...tempNextPageCursor
                })

                foundSessions = {
                  ...foundSessions,
                  completed: [...(completedResponse.payload?.data || [])],
                }

                setSessions((prev: any) => ({
                  ...prev,
                  completed: tempCompleted,
                }));
              }
            }
          }
        } catch (error) {
          console.error("Error fetching sessions:", error);
        } finally {
          setFetchingMore(false);
          setIsSessionLoading(false);
          setIsLoading(false);
          setIsSearching(false);

        }
      },
      300
    ),
    [dispatch]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionBoxRef.current &&
      !suggestionBoxRef.current.contains(event.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  const handleLoadMore = () => {
    if (nextPageCursor) {
      fetchSessions("", "scheduled", selectedOption, sessions, nextPageCursor);
    }
  };

  useEffect(() => {
    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [showDropdown]);


  useEffect(() => {
    activeSessionIdRef.current = activeSessionId;
    if (!activeSessionId && sessions[activeTab].length > 0) {
      onSessionSelect(sessions[activeTab][0]);
      setSelectedBox(0);
    }
  }, [activeSessionId, sessions]);

  useEffect(() => {
    isMounted.current = true;
    setSearchSessions({
      scheduled: [],
      completed: [],
    })
    fetchSessions(searchTerm, activeTab, selectedOption, sessions);
    return () => {
      isMounted.current = false;
      fetchSessions.cancel();
    };
  }, [searchTerm, fetchSessions]);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      fetchSessions(searchTerm, activeTab, selectedOption, sessions);
    }
    if (sessions[activeTab].length > 0) {
      onSessionSelect(sessions[activeTab][0]);
    }
  }, [activeTab, searchTerm, fetchSessions, selectedOption]);

  useEffect(() => {
    if (!searchTerm.trim()) {
      fetchSessions("", "scheduled", selectedOption, sessions);
      fetchSessions("", "completed", selectedOption, sessions);
    }
  }, [fetchSessions]);

  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    if (!searchTerm.trim()) {
      intervalId.current = setInterval(async () => {
        const sessionIds =
          activeTab === "scheduled"
            ? sessions.scheduled.map((item: any) => item.id.toString())
            : sessions.completed.map((item: any) => item.id.toString());
        if (sessionIds.length > 0) {
          const response = await dispatch(sessionBatch({ sessionIds }));
          if (response.meta.requestStatus === "fulfilled") {
            const updatedSessions = response.payload;
            let foundSession: any = updatedSessions.find(
              (updated: any) => activeSessionIdRef.current == updated.id
            );
            // const updatedTabSessions = sessions[activeTab].map((session: any) => {
            //   if (session?.id == activeSessionIdRef.current) {
            //     return { ...session, ...foundSession };
            //   } else {
            //     return session;
            //   }
            // });
            let tempSessions = { ...sessions, [activeTab]: [...updatedSessions] }
            if (sessions[activeTab].length > 0) {
              let tempCompletedSessions: Sessions = { completed: [], scheduled: [] };
              for (let i = 0; i < sessions["scheduled"].length; i++) {
                tempCompletedSessions["scheduled"].push({
                  ...sessions["scheduled"][i],
                  ...tempSessions["scheduled"][i]
                })
              }
              for (let i = 0; i < sessions["completed"].length; i++) {
                tempCompletedSessions["completed"].push({
                  ...sessions["completed"][i],
                  ...tempSessions["completed"][i]
                })
              }
              foundSession = [...tempCompletedSessions[activeTab]].find(
                (updated: any) => activeSessionIdRef.current == updated.id
              );
              setBatchSessions({ ...tempCompletedSessions });
            }
            if (foundSession) {
              const sessionIndex = tempSessions[activeTab].findIndex(
                (session) => session.id === activeSessionIdRef.current
              );
              onSessionSelect(foundSession);
              setSelectedBox(sessionIndex);
            }
          }
        }
      }, 2000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [dispatch, activeTab, sessions, searchTerm]);

  useEffect(() => {
    if (sessions[activeTab].length <= batchSessions[activeTab].length) {
      setSessions({ ...batchSessions })
    }
  }, [batchSessions, activeTab])

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim()) {
      setShowDropdown(false)
      // setShowSuggestions(true);
      setIsSearching(true);
    } else {
      // setShowSuggestions(false);
      fetchSessions("", activeTab, selectedOption, sessions);
      setIsSearching(false);
    }
  };

  return (
    <SessionInfoBox>
      <Tabs>
        <Tab
          isActive={activeTab === "scheduled"}
          onClick={() => handleTabChange("scheduled")}
        >
          Scheduled
        </Tab>
        <Tab
          isActive={activeTab === "completed"}
          onClick={() => handleTabChange("completed")}
        >
          Completed
        </Tab>
      </Tabs>
      <SearchBox>
        <SearchInputAndDropdown>
          <DropdownContainer ref={dropdownRef}>
            <DropdownButton onClick={handleDropdownToggle}>
              {selectedOption === "name" ? "Principal" : toCamelCase(selectedOption) || "Search By"}
              <DownIcon />
            </DropdownButton>
            {showDropdown && (
              <DropdownMenu showDropdown={showDropdown}>
                <DropdownItem onClick={() => handleOptionSelect("name")}>Principal</DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("notary")}>Notary</DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("Appt ID")}>Appointment ID</DropdownItem>
                <DropdownItem onClick={() => handleOptionSelect("email")}>Email</DropdownItem>
              </DropdownMenu>
            )}
          </DropdownContainer>
          <SearchInput
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          {isSearching ? <LoadingIcon /> : <SearchIcon />}
        </SearchInputAndDropdown>
      </SearchBox>

      <ScheduleBoxContainer isLoading={isSessionLoading}>
        {isSessionLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : sessions[activeTab].length === 0 ? (
          <NoItemsFound>No items found</NoItemsFound>
        ) : (
          sessions[activeTab]?.map((item: any, index: number) => (
            <div
              key={item.id}
            // ref={(el) => (sessionRefs.current[item.id] = el)}
            >
              <ScheduleBox
                key={index}
                isSelected={selectedBox === index}
                onClick={() => handleBoxClick(item)}
                item={item}
                activeTab={activeTab}
              />
            </div>
          ))
        )}
        {nextPageCursor[activeTab] && !isSessionLoading && !searchTerm && (
          <ButtonBox>
            {fetchingMore ? (
              <FetchMoreLoaderContainer>
                <FetchMoreLoader />
              </FetchMoreLoaderContainer>
            ) : (
              <LoadMoreButton disabled={isLoading} onClick={handleLoadMore}>
                {"Load More"}
              </LoadMoreButton>
            )}

          </ButtonBox>
        )}
      </ScheduleBoxContainer>
    </SessionInfoBox>
  );
};

export default SessionTimeline;

const NoItemsFound = styled.div`
  text-align: center;
  color: #888;
  margin-top: 16px;
  font-size: 14.6px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 40.5%;
`;

const FetchMoreLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 40.5%;
`;

const ScheduleBoxContainer = styled.div<{ isLoading: boolean }>`
  padding: 7.2px;
  display: ${(props) => (props.isLoading ? "flex" : "block")};
  justify-content: center;
  overflow-y: auto;
  flex: 1;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 16.2px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 0.9px solid #ccc; /* Reduced */
  padding: 8.1px 16.2px;
  padding-top: 0px;
  justify-content: space-between;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 7px 14px;
  cursor: pointer;
  width: 45%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  border-bottom: 2.7px solid
    ${(props) =>
    props.isActive ? props.theme.colors.secondary : "transparent"};
  color: ${(props) =>
    props.isActive ? props.theme.colors.secondary : "black"};
`;

const Loader = styled.div`
  border: 3.6px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3.6px solid #13160b;
  width: 21.6px;
  height: 21.6px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FetchMoreLoader = styled.div`
  border: 3.6px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3.6px solid #2B70F4;
  width: 14.4px;
  height: 14.4px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SessionInfoBox = styled.div`
  width: 96.2%;
  background-color: #f9f9f9;
  padding: 12.2px 8.1px;
  padding-bottom: 0px;
  border-radius: 8.1px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const SearchBox = styled.div`
  position: relative;
  margin-bottom: 12.5px;
  padding: 3px 6px;
`;

const SearchInput = styled.input`
  width: 54%; /* Reduced from 60% */
  padding: 10px 43px 10px 13px; /* Reduced padding by 10% */
  font-size: 14.6px; /* Reduced from 16.2px */
  border: 1px solid #d9d9d9;
  border-top-right-radius: 6px; /* Reduced from 7px */
  border-bottom-right-radius: 6px;
  outline: none;
`;

const SuggestionsBox = styled.div`
  position: absolute;
  width: 96.5%; /* Reduced from 95% */
  background-color: white;
  left: 5px;
  border: 1px solid #ccc;
  border-radius: 6px; /* Reduced from 7px */
  max-height: 180px; /* Reduced from 200px */
  overflow-y: auto;
  z-index: 10;
  top: calc(100% + 4px);
`;

const SuggestionBoxContainer = styled.div`
  padding: 0px 7px; /* Reduced from 8px */
`;

const SuggestionItem = styled.div`
  padding: 9px; /* Reduced from 10px */
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const NoSuggestions = styled.div`
  padding: 9px; /* Reduced from 10px */
  color: #888;
`;

const DropdownItem = styled.div`
  padding: 9px; /* Reduced from 10px */
  color: #545f71;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const SearchInputAndDropdown = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 11px; /* Reduced from 18px */
  position: relative;
  // padding: 14.4px;
  // width: 86.4%; /* Reduced from 96% */
`;

const DropdownContainer = styled.div`
  width: 36%; /* Reduced from 40% */
`;

const DropdownButton = styled.button`
  width: 100%;
  padding: 10px 3px; /* Reduced padding by 10% */
  font-size: 14.6px; /* Reduced from 16.2px */
  border: 1px solid #d9d9d9;
  border-top-left-radius: 6px; /* Reduced from 7px */
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #5e5d5d;
`;

const DropdownMenu = styled.div<{ showDropdown: boolean }>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%; /* Reduced from 100% */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px; /* Reduced from 7px */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${(props) => (props.showDropdown ? "block" : "none")};
`;

const LoadingIcon = styled(FaSpinner)`
  position: absolute;
  right: 14px; /* Reduced from 16px */
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SearchIcon = styled(GrSearch)`
  position: absolute;
  right: 14px; /* Reduced from 16px */
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
`;

const LoadMoreButton = styled.button`
  margin: 0px auto;
  background: white;
  color: #2b70f4;
  border: none;
  font-size: 14.4px; /* Reduced from 16px */
  border-radius: 3.6px; /* Reduced from 4px */
  cursor: pointer;
  box-shadow: 0px 3.6px 5.4px rgba(0, 0, 0, 0.09); /* Reduced from 4px 6px */
  padding: 9px 27px; /* Reduced padding by 10% */
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 5.4px 7.2px rgba(0, 0, 0, 0.13); /* Reduced from 6px 8px */
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
`;