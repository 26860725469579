import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  AppDispatch,
  createSession,
  getOfficials,
  sendNotification,
  uploadDoc,
  uploadPDF,
  getDocumentWithFile,
  copyDocument,
  deleteSession,
  deleteRequest,
} from "../store";
import { Layout } from "../components/Layout";
import { ReactComponent as UploadIcon } from "../assets/bytesize_upload.svg";
import { ReactComponent as TrashIcon } from "../assets/trash.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../utils/localStorageUtil";
import { delay, getUnixTimestamp } from "../utils/helper";
import { TIME_ZONES } from "../utils/sessionHelper";
import { checkFeatureAvailability } from "../utils/permissions";
import { API_STATUS } from "../utils";
import moment from "moment";

type CustomFile = File & {
  date: string;
  originalName: string;
};

const CreateSessionPage: React.FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const session = location.state?.session;
  console.log("session in location", session)
  const isSessionRequest = location.state?.isSessionRequest;
  const isSessionInsideRequest = location.state?.isSessionInsideRequest;
  const [currentStep, setCurrentStep] = useState(1);
  const [isPersonallyKnown, setIsPersonallyKnown] = useState(false);
  const [isAssignmentAvailable, setIsAssignmentAvailable] = useState(false);
  const [formData, setFormData] = useState({
    sessionName: session?.name || "",
    signerName: session?.userName || "",
    signerEmail: session?.userEmail || "",
    recipient1: session?.cc || "",
    recipient2: session?.copy2 || "",
    payerEmail: session?.payerEmail || "",
    officialPrivateNotes: session?.message || "",
    date: session?.requestedDatetime
      ? new Date(session.requestedDatetime).toISOString().split("T")[0]
      : session?.scheduledAt
        ? new Date(session.scheduledAt._seconds * 1000)
          .toISOString()
          .split("T")[0]
        : "",
    time: session?.requestedDatetime
      ? moment(session?.requestedDatetime).format('HH:mm')
      : session?.scheduledAt
        ? new Date(session.scheduledAt._seconds * 1000)
          .toISOString()
          .split("T")[1]
          .slice(0, 5)
        : "",
    typeOfNotarization: session?.typeOfNotarization || "",
    assignedToNotary: session?.assignedToNotary || "",
    message: session?.message || "",
    timeZone: session?.userTimezone || "",
    phone: session?.userPhone || "",
    appointmentId: session?.appointmentId?.toString() || "",
    appointmentType: session?.appointmentType || "",
    official: null,
  });

  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [loadingCreateSession, setLoadingCreateSession] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FormData[]>(
    session?.documents?.map((doc: any) => ({
      name: doc.displayedName,
      lastModified: new Date(doc.updatedAt || doc.createdAt).getTime(),
      size: 0,
      type: "application/pdf",
      date: new Date(doc.updatedAt || doc.createdAt)
        .toISOString()
        .split("T")[0],
      id: doc?.id,
    })) || []
  );
  const [officials, setOfficials] = useState<any[]>([]);

  const maxFiles = 40;

  useEffect(() => {
    (async () => {
      let officialsTemp: any[] = [];
      const getOfficialsResponse = await dispatch(getOfficials({}));
      if (getOfficialsResponse.meta.requestStatus === "fulfilled") {
        setOfficials([...getOfficialsResponse.payload]);
        officialsTemp = [...getOfficialsResponse.payload];
      }
      if (getOfficialsResponse.meta.requestStatus == "rejected") {
        toast.error("Something went wrong", {
          autoClose: 5000,
        });
      }
      const permissions = getFromLocalStorage("permissions");
      const isAvailable = permissions?.includes("notaryScheduling") ?? false;
      setIsAssignmentAvailable(isAvailable);
      if (session?.isPersonallyKnown) {
        setIsPersonallyKnown(session?.isPersonallyKnown);
      } else {
        const savedFormData = getFromLocalStorage("createSessionFormData");
        if (savedFormData) {
          setFormData(savedFormData);
        }
        const savedIsPersonallyKnown = getFromLocalStorage("isPersonallyKnown");
        if (savedIsPersonallyKnown !== null) {
          setIsPersonallyKnown(savedIsPersonallyKnown);
        }
      }
      if (session?.officialName) {
        officialsTemp = officialsTemp?.find(
          (item: any) => item?.name == session?.officialName
        );
        setOfficials([...getOfficialsResponse.payload]);
        setFormData((prevData) => ({
          ...prevData,
          assignedToNotary: session?.officialName,
        }));
      }
      if (session?.typeOfNotarization) {
        setFormData((prevData) => ({
          ...prevData,
          typeOfNotarization: session?.typeOfNotarization,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          typeOfNotarization: "1",
        }));
      }
      if (session?.payerEmail) {
        setFormData((prevData) => ({
          ...prevData,
          payerEmail: session?.payerEmail,
        }));
      }

      if (session?.message) {
        setFormData((prevData) => ({
          ...prevData,
          officialPrivateNotes: session?.message,
        }));
      }
      if (session?.userTimezone) {
        setFormData((prevData) => ({
          ...prevData,
          timeZone: session?.userTimezone,
        }));
      }
      if (session?.message) {
        setFormData((prevData) => ({
          ...prevData,
          message: session?.message,
        }));
      }
      if (session?.userPhone) {
        setFormData((prevData) => ({
          ...prevData,
          phone: session?.userPhone,
        }));
      }
    })();

  }, []);

  useEffect(() => {
    saveToLocalStorage("createSessionFormData", formData);
  }, [formData]);

  useEffect(() => {
    saveToLocalStorage("isPersonallyKnown", isPersonallyKnown);
  }, [isPersonallyKnown]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "assignedToNotary") {
      const selectedOfficial = officials.find(
        (official) => official.name === value
      );

      setFormData((prevData) => ({
        ...prevData,
        assignedToNotary: value,
        official: selectedOfficial,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  console.log("form data===>", formData)

  const isStep1Valid = () => {
    if (!formData.sessionName) {
      toast.error("Session name is required.", { autoClose: 5000 });
      return false;
    }
    if (!formData.signerName) {
      toast.error("Signer name is required.", { autoClose: 5000 });
      return false;
    }
    if (!formData.signerEmail) {
      toast.error("Signer email is required.", { autoClose: 5000 });
      return false;
    }
    if (!formData.date) {
      toast.error("Date is required.", { autoClose: 5000 });
      return false;
    }
    if (!formData.time) {
      toast.error("Time is required.", { autoClose: 5000 });
      return false;
    }
    if (!formData.typeOfNotarization) {
      toast.error("Type of notarization is required.", { autoClose: 5000 });
      return false;
    }
    return true;
  };

  const handleContinue = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (isStep1Valid()) {
      setCurrentStep(2);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    const files = Array.from(event.dataTransfer.files) as File[];
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return ["doc", "docx", "pdf", "txt", "rtf", "odt"].includes(
        fileExtension!
      );
    });

    if (validFiles.length !== files.length) {
      toast.error("Only document files are allowed.", {
        autoClose: 5000,
      });
    }

    if (uploadedFiles.length + validFiles.length <= maxFiles) {
      const formDataArray = validFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("date", getTodayDate());
        formData.append("originalName", file.name);
        return formData;
      });

      // Update the uploadedFiles state with the FormData objects
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...formDataArray, // Add the new FormData objects to the existing ones
      ]);
    } else {
      toast.error(`You can upload a maximum of ${maxFiles} files.`, {
        autoClose: 5000,
      });
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return ["doc", "docx", "pdf", "txt", "rtf", "odt"].includes(
          fileExtension!
        );
      });

      if (validFiles.length !== files.length) {
        toast.error("Only document files are allowed.", {
          autoClose: 5000,
        });
      }

      if (uploadedFiles.length + validFiles.length <= maxFiles) {
        const formDataArray = validFiles.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("date", getTodayDate());
          formData.append("originalName", file.name);
          return formData;
        });

        setUploadedFiles((prevFiles) => [...prevFiles, ...formDataArray]);
      } else {
        toast.error(`You can upload a maximum of ${maxFiles} files.`, {
          autoClose: 5000,
        });
      }
    }
  };

  const handleFileDelete = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleCreateSession = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (uploadedFiles.length == 0) {
        toast.error("Please upload the document", {
          autoClose: 5000,
        });
        return
      }
      const scheduleAt = getUnixTimestamp(formData.date, formData.time);
      setLoadingCreateSession(true);
      console.log("formData", formData)
      const nameParts = formData?.signerName.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.length > 1 ? nameParts[1] : "";
      let payload: any = {
        documentsCount: uploadedFiles.length,
        emailCopies: [formData.recipient1, formData.recipient2].filter(Boolean),
        name: formData.sessionName,
        message: formData.message,
        officialPrivateNotes: formData.officialPrivateNotes || null,
        scheduledAt: scheduleAt,
        typeOfNotarization: +formData?.typeOfNotarization,
        user: {
          email: formData.signerEmail,
          firstName: firstName,
          fullName: formData.signerName,
          lastName: lastName,
          phone: "",
        },
        official: formData.official,
      };

      if (formData.appointmentId) {
        payload = {
          ...payload,
          appointmentId: parseInt(formData.appointmentId),
        };
      }
      if (formData.appointmentType) {
        payload = {
          ...payload,
          appointmentType: formData.appointmentType,
        };
      }
      if (session?.multipleSigner) {
        payload = {
          ...payload,
          multipleSigner: session?.multipleSigner === "yes",
        };
      }
      if (isPersonallyKnown) {
        payload = {
          ...payload,
          isPersonallyKnown,
        };
      }
      if (formData.message) {
        payload = {
          ...payload,
          message: formData.message,
        };
      }
      if (formData.timeZone) {
        payload = {
          ...payload,
          userTimezone: formData.timeZone,
        };
      }
      if (formData.phone) {
        payload = {
          ...payload,
          userPhone: formData.phone,
        };
      }
      if (formData.payerEmail) {
        payload = {
          ...payload,
          payerEmail: formData.payerEmail,
        };
      }

      console.log("formData", formData);
      console.log("payload", payload);
      const createSessionResponse = await dispatch(
        createSession({
          ...payload,
        })
      );

      if (createSessionResponse.meta.requestStatus === "fulfilled") {
        const sessionResponse = createSessionResponse.payload;
        let documentsResponse: any[] = [];

        for (let i = 0; i < uploadedFiles.length; i++) {
          const formData = uploadedFiles[i] as any;
          if (!(formData instanceof FormData)) {
            const fileName = i === 0 ? `document.pdf` : `document${i}.pdf`;
            const copyDocumentResponse = await dispatch(
              copyDocument({
                documentId: formData?.id,
                sessionId: sessionResponse.id,
                sessionDocumentFilename: fileName,
              })
            );
            if (copyDocumentResponse.meta.requestStatus === "fulfilled") {
              const originalFileName = formData?.name;

              if (copyDocumentResponse.payload?.etag) {
                const documentWithFileResponse = await dispatch(
                  getDocumentWithFile({
                    fileName,
                    sessionId: sessionResponse.id,
                    name: originalFileName,
                    hash: copyDocumentResponse.payload.etag,
                  })
                );

                if (
                  documentWithFileResponse.meta.requestStatus === "fulfilled"
                ) {
                  documentsResponse.push(documentWithFileResponse.payload);
                }
              } else {
                const deleteResponse = await dispatch(deleteSession({
                  sessionId: sessionResponse?.id
                }))
                console.error("ETag not found");
              }
            } else {
              const deleteResponse = await dispatch(deleteSession({
                sessionId: sessionResponse?.id
              }))
              toast.error("Something went wrong", {
                autoClose: 5000,
              });
            }
          } else {
            const originalFileName = formData.get("originalName") as string;
            const file = formData.get("file") as File;
            const fileName = i === 0 ? `document.pdf` : `document${i}.pdf`;

            const uploadDocResponse = await dispatch(
              uploadDoc({
                sessionId: sessionResponse.id,
                fileName,
              })
            );

            if (uploadDocResponse.meta.requestStatus === "fulfilled") {
              const uploadedDoc = uploadDocResponse.payload;
              const uploadPdfResponse = await dispatch(
                uploadPDF({
                  url: uploadedDoc.url,
                  file,
                })
              );

              if (uploadPdfResponse.payload?.etag) {
                const documentWithFileResponse = await dispatch(
                  getDocumentWithFile({
                    fileName,
                    sessionId: sessionResponse.id,
                    name: originalFileName,
                    hash: uploadPdfResponse.payload.etag,
                  })
                );

                if (
                  documentWithFileResponse.meta.requestStatus === "fulfilled"
                ) {
                  documentsResponse.push(documentWithFileResponse.payload);
                } else {
                  const deleteResponse = await dispatch(deleteSession({
                    sessionId: sessionResponse?.id
                  }))
                  throw new Error("Error in document");
                }
              } else {
                const deleteResponse = await dispatch(deleteSession({
                  sessionId: sessionResponse?.id
                }))
                throw new Error("ETag not found");
              }
            } else {
              const deleteResponse = await dispatch(deleteSession({
                sessionId: sessionResponse?.id
              }))
              throw new Error("Error while uploading");
            }
          }
        }

        const invalidDocuments = documentsResponse.some(
          (doc) => doc.fileCheckResult && !doc.fileCheckResult.ok
        );

        if (invalidDocuments) {
          throw new Error("Invalid document");
        }

        const notificationResponse = await dispatch(
          sendNotification({
            status: "created",
            sessionId: sessionResponse.id,
          })
        );

        if (notificationResponse.meta.requestStatus === "fulfilled") {


        }
        if (isSessionInsideRequest) {
          const deleteRequestResponse = await dispatch(
            deleteRequest({ requestId: session?.id })
          );
          if (deleteRequestResponse.meta.requestStatus == API_STATUS.fulfilled) {
            toast.success("Session created successfully", {
              autoClose: 5000,
            });
            removeFromLocalStorage("createSessionFormData");
            removeFromLocalStorage("isPersonallyKnown");
            navigate(`/session/${createSessionResponse?.payload?.id}`);
          }
        }
        else {
          toast.success("Session created successfully", {
            autoClose: 5000,
          });
          removeFromLocalStorage("createSessionFormData");
          removeFromLocalStorage("isPersonallyKnown");
          navigate(`/session/${createSessionResponse?.payload?.id}`);
        }
      } else {
        throw new Error("Please try again");
      }
    } catch (err) {
      console.log(":error:")
      setLoadingCreateSession(false);
      console.error(err);
      toast.error("Failed to create session. Please try again.", {
        autoClose: 5000,
      });
    }
  };
  const onClose = () => {
    navigate("/");
  };

  const generateTimeOptions = () => {
    const options = [{ label: "--:--", value: "" }];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const displayHour = hour % 12 || 12;
        const ampm = hour < 12 ? "AM" : "PM";
        const displayTime = `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`;
        const valueTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push({ label: displayTime, value: valueTime });
      }
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  return (
    <Layout>
      <PageContainer>
        <Container>
          <StepTabs>
            <StepTabsBox currentStep={currentStep}>
              <StepTab
                active={currentStep === 1 || !!isStep1Valid()}
                onClick={() => setCurrentStep(1)}
              >
                <StepCircle active={currentStep === 1 || !!isStep1Valid()}>
                  1
                </StepCircle>
                <StepLabel active={currentStep === 1 || !!isStep1Valid()}>
                  Session Information
                </StepLabel>
              </StepTab>
              <StepTab
                active={currentStep === 2}
                onClick={() => isStep1Valid() && setCurrentStep(2)}
              >
                <StepCircle active={currentStep === 2}>2</StepCircle>
                <StepLabel active={currentStep === 2}>Upload Document</StepLabel>
              </StepTab>
            </StepTabsBox>
          </StepTabs>
          {currentStep === 1 && (
            <Form>
              <Row>
                <Column>
                  <Label>
                    Session Name of Reference <Esterik>*</Esterik>
                  </Label>
                  <Input
                    type="text"
                    name="sessionName"
                    placeholder="Type here"
                    value={formData.sessionName}
                    onChange={handleInputChange}
                    required
                  />
                </Column>
                <Column>
                  <Label>
                    Type of Notarization<Esterik>*</Esterik>
                  </Label>
                  <StyledSelect
                    name="typeOfNotarization"
                    value={formData?.typeOfNotarization}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">
                      Select Notorization<Esterik>*</Esterik>
                    </option>
                    <option value="1">Acknowledgment</option>
                    <option value="2">Jurat</option>
                    <option value="3">Verification</option>
                    <option value="4">Deposition</option>
                    <option value="5">Oath/Affirmation</option>
                  </StyledSelect>
                </Column>
                {isAssignmentAvailable && (
                  // <Row>
                    <Column style={{ flex: 1 }}>
                      <Label>
                        Assigned to Notary <Esterik>*</Esterik>
                      </Label>
                      <StyledSelect
                        name="assignedToNotary"
                        value={formData.assignedToNotary}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Notary</option>
                        {officials.map((official) => (
                          <option key={official.id} value={official.name}>
                            {official.name}
                          </option>
                        ))}
                      </StyledSelect>
                     
                    </Column>
                  // </Row>
                )}
              </Row>
              <Row>
                <Column>
                  <Label>
                    Signers Name <Esterik>*</Esterik>
                  </Label>
                  <Input
                    type="text"
                    name="signerName"
                    placeholder="Type here"
                    value={formData.signerName}
                    onChange={handleInputChange}
                    required
                  />
                </Column>
                <Column>
                  <Label>
                    Signers Email <Esterik>*</Esterik>
                  </Label>
                  <Input
                    type="email"
                    name="signerEmail"
                    placeholder="Type here"
                    value={formData.signerEmail}
                    onChange={handleInputChange}
                    required
                  />
                </Column>
              </Row>

              {isSessionRequest ? (
                <>
                  <Row>
                    <Column>
                      <Label>
                        Date <Esterik>*</Esterik>
                      </Label>
                      <Input
                        type="date"
                        name="date"
                        placeholder="Select a Date"
                        value={formData.date}
                        onChange={handleInputChange}
                        min={getTodayDate()}
                        required
                      />
                    </Column>
                    <Column>
                      <Label>
                        Time <Esterik>*</Esterik>
                      </Label>
                      <Input
                        type="time"
                        name="time"
                        placeholder="Select a Time"
                        value={formData.time}
                        onChange={handleInputChange}
                        required
                      />
                    </Column>
                    <Column style={{ flex: "1" }}>
                      <Label>
                        Time Zone<Esterik>*</Esterik>
                      </Label>
                      <StyledSelect
                        name="timeZone"
                        value={formData.timeZone}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Time Zone</option>
                        {TIME_ZONES.map((zone) => (
                          <option key={zone.name} value={zone.value}>
                            {zone.name}
                          </option>
                        ))}
                      </StyledSelect>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Label>Send Copy to Recipient 1 (optional)</Label>
                      <Input
                        type="text"
                        name="recipient1"
                        placeholder="Type email here"
                        value={formData.recipient1}
                        onChange={handleInputChange}
                      />
                    </Column>
                    <Column>
                      <Label>Send Copy to Recipient 2 (optional)</Label>
                      <Input
                        type="text"
                        name="recipient2"
                        placeholder="Type email here"
                        value={formData.recipient2}
                        onChange={handleInputChange}
                      />
                    </Column>
                    <Column>
                      <Label>Payer Email (optional)</Label>
                      <Input
                        type="email"
                        name="payerEmail"
                        placeholder="Type email here"
                        value={formData.payerEmail}
                        onChange={handleInputChange}
                      />
                    </Column>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Column>
                      <Label>
                        Date <Esterik>*</Esterik>
                      </Label>
                      <Input
                        type="date"
                        name="date"
                        placeholder="Select a Date"
                        value={formData.date}
                        onChange={handleInputChange}
                        min={getTodayDate()}
                        required
                      />
                    </Column>
                    <Column>
                      <Label>
                        Time <Esterik>*</Esterik>
                      </Label>
                      <StyledSelect
                        name="time"
                        value={formData.time}
                        onChange={handleInputChange}
                        required
                      >
                        {timeOptions.map((time) => (
                          <option key={time.label} value={time.value}>
                            {time.label}
                          </option>
                        ))}
                      </StyledSelect>
                      {/* <Input
                        type="time"
                        name="time"
                        placeholder="Select a Time"
                        value={formData.time}
                        onChange={handleInputChange}
                        required
                      /> */}
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Label>Send Copy to Recipient 1 (optional)</Label>
                      <Input
                        type="text"
                        name="recipient1"
                        placeholder="Type email here"
                        value={formData.recipient1}
                        onChange={handleInputChange}
                      />
                    </Column>
                    <Column>
                      <Label>Send Copy to Recipient 2 (optional)</Label>
                      <Input
                        type="text"
                        name="recipient2"
                        placeholder="Type email here"
                        value={formData.recipient2}
                        onChange={handleInputChange}
                      />
                    </Column>
                  </Row>
                </>
              )}
              <AdditionalRow>
                <AdditionalMessageColumn>
                  <Label>Additional Message to Signer (optional)</Label>
                  <Input
                    type="text"
                    name="message"
                    placeholder="Type your message here"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  <CheckboxSaveFieldWrapper>
                    <CheckboxInput type="checkbox" />
                    <CheckboxLabel>Save Field Entry</CheckboxLabel>
                  </CheckboxSaveFieldWrapper>
                </AdditionalMessageColumn>
              </AdditionalRow>
                <Column
                  style={{ flex: "0 0 33.33%", justifyContent: "flex-end" }}
                >
                  <CheckboxWrapper>
                    <CheckboxInput
                      type="checkbox"
                      checked={isPersonallyKnown}
                      onChange={(e) => setIsPersonallyKnown(e.target.checked)}
                    />
                    <CheckboxLabel>
                      Signer is personally known to notary public
                    </CheckboxLabel>
                  </CheckboxWrapper>
                </Column>

              <ButtonGroup>
                <Button onClick={onClose} variant="cancel">
                  Close
                </Button>
                <Button onClick={(e) => handleContinue(e)} variant="save">
                  Continue
                </Button>
              </ButtonGroup>
            </Form>
          )}
          {currentStep === 2 && (
            <Form onSubmit={handleCreateSession}>
              <DragDropArea
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                dragging={dragging}
              >
                <UploadIcon />
                <DragDropText>Drag files here</DragDropText>
                <OrText>OR</OrText>
                <BrowseText
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  <BrowseLink href="#">BROWSE YOUR COMPUTER</BrowseLink>
                </BrowseText>
                <HiddenFileInput
                  id="fileInput"
                  type="file"
                  onChange={handleFileInputChange}
                  multiple
                />
              </DragDropArea>
              <FileLimitText>
                {uploadedFiles.length} / {maxFiles}
              </FileLimitText>
              {uploadedFiles.length > 0 && (
                <FileTable>
                  <thead>
                    <tr>
                      <th style={{ width: "60%" }}>Document Name</th>
                      <th style={{ width: "30%" }}>Document Date</th>
                      <th style={{ width: "10%" }}>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedFiles.map((formData: any, index) => {
                      const originalName =
                        formData instanceof FormData
                          ? formData.get("originalName")
                          : formData?.name;
                      const date =
                        formData instanceof FormData
                          ? formData.get("date")
                          : formData?.date;

                      return (
                        <tr key={index}>
                          <td>
                            <Input
                              type="text"
                              name={`documentName${index}`}
                              placeholder="Type document name"
                              defaultValue={originalName as string}
                            />
                          </td>
                          <td>
                            <Input
                              type="date"
                              name={`documentDate${index}`}
                              defaultValue={date as string}
                              min={getTodayDate()}
                            />
                          </td>
                          <td>
                            <DeleteButton
                              onClick={() => handleFileDelete(index)}
                            >
                              <TrashIcon />
                            </DeleteButton>
                          </td>
                        </tr>
                      );
                    })}
                    {/* {uploadedFiles.map((formData, index) => (
  <tr key={index}>
    <td>
      <Input
        type="text"
        name={`documentName${index}`}
        placeholder="Type document name"
        defaultValue={formData.get('originalName') as string}
      />
    </td>
    <td>
      <Input
        type="date"
        name={`documentDate${index}`}
        defaultValue={formData.get('date') as string}
        min={getTodayDate()}
      />
    </td>
    <td>
      <DeleteButton onClick={() => handleFileDelete(index)}>
        <TrashIcon />
      </DeleteButton>
    </td>
  </tr>
))} */}
                  </tbody>
                </FileTable>
              )}

              <ButtonGroup>
                <Button onClick={() => setCurrentStep(1)} variant="cancel">
                  Back
                </Button>
                <Button type="submit" variant="save">
                  {loadingCreateSession ? <LoadingSpinner /> : "Finish"}
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default CreateSessionPage;

const PageContainer = styled.div`
  width: 90%;
  background: white;
  margin: 40px auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin: auto;
  max-width: 1212px;
  height: fit-content;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const StepTabs = styled.div`
  display: flex;
  justify-content: center;
  width: 85%;
  margin-bottom: 20px;
`;

const StepTabsBox = styled.div<{ currentStep: number }>`
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 20px;
  margin-right: 12%;
  margin-right:  ${({ currentStep }) => (currentStep==1 ? "12%" : "0")};;
`;

const StepTab = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  color: ${({ active }) => (active ? "#000" : "#ccc")};
`;

const StepCircle = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.light : "#F1F1F1"};
  color: ${({ active, theme }) =>
    active ? theme.colors.secondaryTextColor : "#CCCBCB"};
  font-size: 20px;
  font-weight: bold;
  border: 1px solid
    ${({ active, theme }) =>
    active ? theme.colors.secondaryTextColor : "#CCCBCB"};
`;

const StepLabel = styled.div<{ active: boolean }>`
  margin-top: 10px;
  font-size: 14px;
  color: ${({ active, theme }) =>
    active ? theme.colors.secondaryTextColor : "#CCCBCB"};
  font-weight: 450;
`;

const Form = styled.form`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  width: 85%;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  // flex-wrap: wrap;
`;

const AdditionalRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const Column = styled.div`
  flex: 0 0 33.33%;
  // max-width: 33.33%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  padding: 0 10px;
`;

const AdditionalMessageColumn = styled.div`
  display: flex;
  flex: 0 0 calc(66.66% + 10px);
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 10px;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  font-family: "Inter", sans-serif;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const StyledSelect = styled.select`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  background: url("path/to/your/custom/dropdown-icon.png") no-repeat right;
  background-position-x: calc(100% - 10px);
`;

const CheckboxInput = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxSaveFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  // margin-bottom: 5px;
  color: ${(props) => props.theme.colors.secondaryTextColor};
`;

const Esterik = styled.span`
  color: ${(props) => props.theme.colors.red};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const DragDropArea = styled.div<{ dragging: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed ${({ dragging }) => (dragging ? "#000" : "#ccc")};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const DragDropText = styled.div`
  font-size: 14px;
  margin: 10px 0;
  font-weight: 450;
`;

const OrText = styled.div`
  font-size: 14px;
  margin: 10px 0;
  font-weight: 450;
`;

const BrowseText = styled.div`
  font-size: 14px;
  padding: 20px 40px;
  background: ${(props) => props.theme.colors.lightGrey};
  border-radius: 40px;
  font-weight: 450;
`;

const BrowseLink = styled.a`
          color: ${(props) => props.theme.colors.primary};
          text-decoration: none;

          &:hover {
            text - decoration: underline;
  }
          `;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileLimitText = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const FileTable = styled.table`
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;

          th {
            border - bottom: 1px solid #ccc;
  }

          th,
          td {
            padding: 10px;
          text-align: left;
  }

          th {
            color: ${(props) => props.theme.colors.secondaryTextColor};
          font-size: 12px;
  }
          `;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0 % {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
