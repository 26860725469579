export const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  export const getFromLocalStorage = (key: string) => {
    const value = localStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error(`Failed to parse localStorage item with key "${key}":`, value, error);
      return null;
    }
  };
  
  
  export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
  };
  