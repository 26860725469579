import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { FaRegIdCard } from "react-icons/fa";
import { isUserOnline, STAGES_BEFORE_CAMERA_CHECK } from "../../utils/sessionHelper";
import { ReactComponent as NetworkErrorIcon } from "../../assets/networkError.svg";
import { ReactComponent as VideoFilledIcon } from "../../assets/video-filled.svg";
import { ReactComponent as VideoEmptyIcon } from "../../assets/nowebrtc.svg";
import { ReactComponent as WebRtcErrorIcon } from "../../assets/webrtc-error.svg";

interface ScheduleBoxProps {
  isSelected: boolean;
  item: any;
  activeTab: string;
  onClick: () => void;
}

const ScheduleBox: React.FC<ScheduleBoxProps> = ({
  isSelected,
  onClick,
  item,
  activeTab
}) => {
  const [networkError, setNetworkError] = useState(false);
  const [isSignerWebRTCOk, setIsSignerWebRTCOk] = useState(false);

  const initialDate = useMemo(() => {
    return item?.scheduledAt?._seconds
      ? moment(item.scheduledAt._seconds * 1000).toDate()
      : new Date();
  }, [item?.scheduledAt]);

  // const isWithinFourHours = useMemo(() => {
  //   const currTimestamp = +new Date();
  //   return item?.scheduledAt?._seconds * 1000 - currTimestamp < 0;
  // }, [item?.scheduledAt]);

  const scheduleStatus = useMemo(() => {
    const currTimestamp = +new Date();
    const scheduledTimestamp = item?.scheduledAt?._seconds * 1000;

    if (scheduledTimestamp <= currTimestamp) {
      return "started";
    } else if (scheduledTimestamp - currTimestamp <= 30 * 60 * 1000) {
      return "soon";
    } else {
      return "future";
    }
  }, [item?.scheduledAt]);
  const isOfficialOnline = useMemo(() => isUserOnline(item), [item]);
  const isSignerOnline = useMemo(() => isUserOnline(item, 'signerLastOnlineAt'), [item]);

  useEffect(() => {
    if (!item.isCompleted) {
      let isSignerOnlineTemp = isUserOnline(item, 'signerLastOnlineAt');
      // setIsSignerOnline(isSignerOnlineTemp);
      if (
        !item.userIO ||
        !item.userIO.audioDevices ||
        !item.userIO.videoDevices ||
        !(item.userIO.audioDevices.length && item.userIO.videoDevices.length)
      ) {
        if (
          !STAGES_BEFORE_CAMERA_CHECK.includes(item.stage) &&
          isSignerOnlineTemp
        ) {
          setNetworkError(true);
        } else {
          setNetworkError(false);
        }
      } else {
        setNetworkError(false);
        setIsSignerWebRTCOk(true);
      }
    }
  }, [item.isCompleted, item.userIO, item.stage]);

  return (
    <Container isselected={isSelected} onClick={onClick}>
      <Header>
        <LeftText>
          <Name>
            {item?.name}
          </Name>

          <IconsBox>
            {item?.idCaptureError && <FaRegIdCard width={"17px"} height={"17px"} color="#ea4125" />}
            {(networkError || item?.networkError) && <NetworkErrorIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && (item?.userVideoJoined)) && <VideoFilledIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && !(item?.userVideoJoined) && !item?.isSignerWebRTCError) && <VideoEmptyIcon width={"17px"} height={"17px"} />}
            {(!item?.isCompleted && (isSignerOnline || item?.isSignerOnline) && item?.isSignerWebRTCError) && <WebRtcErrorIcon width={"17px"} height={"17px"} />}
          </IconsBox>


        </LeftText>
        {/* {
          item?.isViewed && (<RightText>Viewed</RightText>)
        } */}
      </Header>
      <NotaryOwner>
        <ScheduleText>
          {moment(initialDate).format("M/D/YY h:mm a")}
        </ScheduleText>{" "}
        {((!isOfficialOnline && isSignerOnline) || isOfficialOnline) && (
          <InfoBlock>
            <Value>{item?.stage}</Value>
          </InfoBlock>
        )}

      </NotaryOwner>
      <InfoRow status={scheduleStatus} activeTab={activeTab}>
        <InfoBlock>
          <Value>{item?.appointmentId}</Value>
        </InfoBlock>
        <InfoBlock>
          <Value>{!isOfficialOnline && isSignerOnline && <StatusDot color="red" />}
            {isOfficialOnline && <StatusDot color="green" />}{item?.official?.name}</Value>
        </InfoBlock>
      </InfoRow>
    </Container>
  );
};

export default ScheduleBox;


export const InfoRow = styled.div<{ status: string; activeTab: string }>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.activeTab !== "scheduled"
      ? "#f7f7f7" 
      : props.status === "started"
      ? "#F4E5E0"
      : props.status === "soon"
      ? "#FEF8DB"
      : "#f7f7f7"};
  margin-top: 6.5px;
  padding: 11.3px;
  border-radius: 7.2px;
`;

export const IconsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5.4px; /* Reduced from 6px */
  margin-top: 2.7px; /* Reduced from 3px */
`;

export const StatusDot = styled.div<{ color: string }>`
  width: 9px; /* Reduced from 10px */
  height: 9px; /* Reduced from 10px */
  background-color: ${(props) =>
    props.color == "green" ? "#41B050" : "#ea4125"};
  border-radius: 50%;
`;

export const Name = styled.span`
  display: block;
  font-size: 15.3px; /* Reduced from 17px */
  font-weight: 540; /* Reduced from 600 */
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  max-width: 16.2ch; /* Reduced from 18ch */
  line-height: 1.35; /* Reduced from 1.5 */
`;

export const Container = styled.div<{
  isselected: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.isselected ? "1.35px solid blue" : "none")}; /* Reduced from 1.5px */
  overflow-y: auto;
  cursor: pointer;
  background: ${(props) =>
    props.isselected ? props.theme.colors.light : "white"};
  box-shadow: rgba(50, 50, 93, 0.225) 0px 5.4px 10.8px -1.8px,
    rgba(0, 0, 0, 0.27) 0px 2.7px 6.3px -2.7px; /* Reduced shadow intensities */
  &:hover {
    background: ${(props) =>
    props.isselected ? props.theme.colors.light : "#f0f0f0"};
    // ${InfoRow} {
    //   background-color: ${(props) => (!props.isselected && "white")};
    // }
  }
  padding: 12.96px; /* Reduced from 14.4px */
  padding-top: 9px; /* Reduced from 10px */
  margin-bottom: 18px;
  border-radius: 4.86px; /* Reduced from 5.4px */
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16.2px; /* Reduced from 18px */
`;

export const LeftText = styled.div`
  color: #333344;
  display: flex;
  align-items: flex-start;
  gap: 9px; /* Reduced from 10px */
  font-weight: 450; /* Reduced from 500 */
  width: 100%;
  justify-content: space-between;
`;

export const RightText = styled.div`
  display: flex;
  align-items: center;
  font-size: 10.8px; /* Reduced from 12px */
  margin-top: 3.6px; /* Reduced from 4px */
  color: ${(props) => props.theme.colors.primary};
  &::before {
    content: "";
    display: inline-block;
    width: 7.2px; /* Reduced from 8px */
    height: 7.2px; /* Reduced from 8px */
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-right: 4.5px; /* Reduced from 5px */
    margin-left: 4.5px; /* Reduced from 5px */
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7.2px; /* Reduced from 8px */
`;

export const Label = styled.span`
  color: #555;
  font-size: 11.3px; /* Reduced from 12.6px */
`;

export const NotaryOwner = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 12.96px; /* Reduced from 14.4px */
  margin-top: 1.8px; /* Reduced from 2px */
`;

export const Value = styled.span`
  font-size: 12.96px; /* Reduced from 14.4px */
  font-weight: 450; /* Reduced from 500 */
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const ScheduleText = styled.span`
  font-size: 12.96px;
  color: #000;
  font-weight: 495;
`;
