import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Select, { components, SingleValue, ActionMeta } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

interface SessionActionDropdownProps {
  onChange: (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  callAction: (action: string) => void;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // width: "85px",
    height: "100%",
    minHeight: "40px",
    // boxShadow: "0px 5px 20px rgba(58, 58, 58, 0.18)"
  }),
  indicatorSeparator: () => ({
    isDisabled: true,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "0 22px",
    paddingLeft: "10px",
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: "10px",
    width: "232px",
    left: "-196px",
  }),
  placeholder: (provided: any) => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    padding: "0",
    display: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007bff"
      : state.isFocused
        ? "lightblue"
        : "white",
    color: state.isSelected ? "white" : "black",
    cursor: "pointer",
  }),
  input: () => ({
    isDisabled: true,
  }),
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <div
      onMouseDown={(e) => {
        e.preventDefault(); // Prevent focus state changes
        props.selectProps.menuIsOpen
          ? props.selectProps.onMenuClose()
          : props.selectProps.onMenuOpen();
      }}
    >
      ▼
    </div>
  </components.DropdownIndicator>
);

const ButtonContainer = styled.div`
   display: flex;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  align-items: center;
  height: 48px;
  gap: 20px;
`;

const ActionButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 22px;
  margin-right: 0;
  cursor: pointer;
   height: 100%;
  font-size: 16px; /* Reduced from 18px */
  border-radius: 10px; /* Reduced from 10px */
  font-weight: 600;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: white;
  margin: 0 8px;
`;

const WithVerticalLine = styled.div`
  height: 100%;
  display: flex;
`;

const SessionActionDropdown: React.FC<SessionActionDropdownProps & { session: any }> = ({
  onChange,
  callAction,
  session
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState({
    value: "joinSession",
    label: "Join session",
  });
  const containerRef = useRef<HTMLDivElement>(null);
  
  const dynamicOptions: OptionType[] = [
    { value: "joinSession", label: "Join session" },
    ...(session?.scheduledAt ? [{ value: "copySession", label: "Copy session link" }] : []),
    ...(session?.scheduledAt ? [{ value: "resendSessionLink", label: "Re-send session link" }] : []),
    { value: "cancelSession", label: "Cancel session" },
    { value: "restartSession", label: "Restart session" },
    { value: "disableVideoAudio", label: `${(session?.settings && session?.settings?.isMeetingDisabled)?"Enable": "Disable"}  video/audio` },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setMenuIsOpen(false);
      }
    };

    if (menuIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuIsOpen]);

  const handleOptionChange = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === "select-option" && newValue) {
      onChange(newValue, actionMeta);
      setSelectedAction(newValue);
      setMenuIsOpen(false);
    }
  };

  const handleAction = async () => {
    console.log("selectedAction?.value", selectedAction?.value)
    await callAction(selectedAction?.value);
    setSelectedAction({ value: "joinSession", label: "Join session" });
  };

  return (
    <ButtonContainer ref={containerRef}>
      <ActionButton onClick={() => handleAction()}>
        {selectedAction.label}
      </ActionButton>
      <WithVerticalLine>

      <VerticalLine />
      <Select
        isSearchable={false}
        onChange={handleOptionChange}
        options={dynamicOptions}
        styles={customStyles}
        components={{ DropdownIndicator }}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
      </WithVerticalLine>
    </ButtonContainer>
  );
};

export default SessionActionDropdown;

export const options: OptionType[] = [
  { value: "joinSession", label: "Join session" },
  { value: "resendSessionLink", label: "Re-send session link" },
  { value: "copySession", label: "Copy session link" },
  { value: "cancelSession", label: "Cancel session" },
  { value: "restartSession", label: "Restart session" },
  { value: "disableVideoAudio", label: "Disable video/audio" },
];