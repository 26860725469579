import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SessionTimeline from "../components/home/SessionTimeLine";
import SessionSummary from "../components/home/SessionSummary";
import { Layout } from "../components/Layout";

const Home: React.FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const navigate = useNavigate();
  const [activeSession, setActiveSession] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<"scheduled" | "completed">("scheduled");
  const isMounted = useRef(true);

  const handleSessionSelect = (session: any) => {
    console.log("session in handleSessionSelect==>", session);
    if (isMounted.current) {
      setActiveSession(session);
      navigate(`/session/${session.id}`, { replace: true });
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Layout>
      <SessionTimelineContainer>
        <SessionTimeline
          onSessionSelect={handleSessionSelect}
          activeSessionId={sessionId}
          onTabChange={setActiveTab}
        />
      </SessionTimelineContainer>
      <SessionSummaryContainer>
        <SessionSummary session={activeSession} activeScheduleTab={activeTab} handleSessionSelect={handleSessionSelect} />
      </SessionSummaryContainer>
    </Layout>
  );
};

export default Home;

const SessionTimelineContainer = styled.div`
  flex: 1;
  max-width: 25%; 
  height: 100%; 
  overflow-y: auto;
  // background: #F9F9F9;
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const SessionSummaryContainer = styled.div`
  flex: 3;
  background: #f9f9f9;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    flex: none;
    width: 100%;
  }
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;
