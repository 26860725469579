import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "../../assets/menu-icon.svg";
import Select, { components, SingleValue, ActionMeta } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

interface SessionActionDropdownProps {
  onChange: (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  callAction: (action: string) => void;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "36px",
    minHeight: "36px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided: any) => ({
    ...provided,
    // marginTop: "9px",
    width: "250px",
    left: "-238px",
    top: "72px",
  }),
  valueContainer: (provided: any) => ({
    display: "none",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "lightblue"
      : "white",
    color: "black",
    cursor: "pointer",
    padding: "9px 8px",
  }),
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <MenuIcon
      onClick={(e) => {
        e.preventDefault();
        props.setMenuIsOpen((prev: boolean) => !prev);
      }}
    />
  </components.DropdownIndicator>
);

const ButtonContainer = styled.div`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 0px;
`;

const SessionActionDropdown: React.FC<
  SessionActionDropdownProps & { session: any }
> = ({ onChange, callAction, session }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState({
    value: "joinSession",
    label: "Join session",
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const dynamicOptions: OptionType[] = [
    { value: "resendDownloadPageLink", label: "Re-send download page link" },
    { value: "copyDownloadPageLink", label: "Copy download page link" },
    ...(session?.receiptUrl
      ? [{ value: "copyReceiptLink", label: "Copy receipt link" }]
      : []),
    { value: "downloadAllDocuments", label: "Download all documents" },
    { value: "sendSessionFeedback", label: "Send session feedback" },
  ];
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setMenuIsOpen(false);
      }
    };

    if (menuIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuIsOpen]);

  const handleOptionChange = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === "select-option" && newValue) {
      console.log("newValue", newValue)
      onChange(newValue, actionMeta);
      setSelectedAction(newValue);
      setMenuIsOpen(false);
      callAction(newValue?.value);
    }
  };

  return (
    <ButtonContainer ref={containerRef}>
      <Select
        isSearchable={false}
        onChange={handleOptionChange}
        options={dynamicOptions}
        styles={customStyles}
        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} setMenuIsOpen={setMenuIsOpen} /> }}
        menuIsOpen={menuIsOpen}
      />
    </ButtonContainer>
  );
};

export default SessionActionDropdown;

export const completedOptions: OptionType[] = [
  { value: "resendDownloadPageLink", label: "Re-send download page link" },
  { value: "copyDownloadPageLink", label: "Copy download page link" },
  { value: "copyReceiptLink", label: "Copy receipt link" },
  { value: "downloadAllDocuments", label: "Download all documents" },
  { value: "sendSessionFeedback", label: "Send session feedback" },
];