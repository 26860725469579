import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../assets/Logo.svg";
import { ReactComponent as SessionIcon } from "../assets/session_icon.svg";
import { ReactComponent as SessionWhiteIcon } from "../assets/session_icon_white.svg";
import { ReactComponent as SessionBookIcon } from "../assets/session_book_icon.svg";
import { ReactComponent as SessionBookWhiteIcon } from "../assets/session_book_white_icon.svg";
import { ReactComponent as SessionRequestIcon } from "../assets/session_request_icon.svg";
import { ReactComponent as SessionRequestWhiteIcon } from "../assets/session_request_white_icon.svg";
import { ReactComponent as CreateSessionIcon } from "../assets/create_session.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { ReactComponent as CreateSessionWhiteIcon } from "../assets/create_session_white.svg";
import { Button } from "./Button";
import { signOut } from "aws-amplify/auth";
import { removeFromLocalStorage } from "../utils/localStorageUtil";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, getNotarySessions, RootState } from "../store";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [dropdownvisible, setDropdownVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const { unreadSessionRequests } = useSelector(
    (state: RootState) => state.session
  );

  const handleLogout = () => {
    setIsLogoutPopupVisible(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsSidebarExpanded(false);
    }
  };

  useEffect(() => {
    dispatch(getNotarySessions({}))
    document.addEventListener("mousedown", handleClickOutside);
    // setUnreadRequestsCount(5);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isActiveHome =
    location.pathname === "/" ||
    (location.pathname.startsWith("/session") &&
      location.pathname !== "/sessionRequests");
  const isActiveNotaryRequest =
    location.pathname === "/sessionRequests" ||
    (location.pathname.startsWith("/notary") &&
      location.pathname !== "/notaryBook");

  const closeLogoutPopup = () => {
    setIsLogoutPopupVisible(false);
  };

  const onLogout = async () => {
    try {
      setIsLogoutLoading(true);
      await signOut();
      setIsLogin(false);
      removeFromLocalStorage("firmId");
      removeFromLocalStorage("officialId");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setIsLogoutLoading(false);
      setIsLogoutPopupVisible(false);
    }
  };

  return (
    <StyledHeader
      ref={sidebarRef}
      isExpanded={isSidebarExpanded}
      onMouseEnter={() => setIsSidebarExpanded(true)}
      onMouseLeave={() => setIsSidebarExpanded(false)}
    >
      <LogoLink to="/">
        <LogoIcon />
      </LogoLink>
      <DesktopMenu>
        <StyledLink
          to="/createSession"
          isActive={location.pathname === "/createSession"}
          isExpanded={isSidebarExpanded}
        >
          {location.pathname === "/createSession" ? (
            <CreateSessionWhiteIcon />
          ) : (
            <CreateSessionIcon />
          )}
          <span>New</span>
        </StyledLink>
        <StyledLink
          to="/"
          isActive={isActiveHome}
          isExpanded={isSidebarExpanded}
        >
          {isActiveHome ? <SessionWhiteIcon /> : <SessionIcon />}
          <span>Sessions</span>
        </StyledLink>
        <StyledLinkRequest
          to="/sessionRequests"
          isActive={isActiveNotaryRequest}
          isExpanded={isSidebarExpanded}
        >
          {isActiveNotaryRequest ? (
            <SessionRequestWhiteIcon />
          ) : (
            <SessionRequestIcon />
          )}
          <span>Requests</span>
          {unreadSessionRequests > 0 && (
            <NotificationBadge>{unreadSessionRequests}</NotificationBadge>
          )}
        </StyledLinkRequest>
        <RecordStyledLink
          to="/notaryBook"
          isActive={location.pathname === "/notaryBook"}
          isExpanded={isSidebarExpanded}
        >
          {location.pathname === "/notaryBook" ? (
            <SessionBookWhiteIcon />
          ) : (
            <SessionBookIcon />
          )}
          <span>Records</span>
        </RecordStyledLink>
       

        <StyledLinkBox
          onClick={() => handleLogout()}
          isExpanded={isSidebarExpanded}
        >
          <LogoutIcon />
          <span>Log out</span>
        </StyledLinkBox>
      </DesktopMenu>
      {isMobileMenuOpen && (
        <MobileMenu isOpen={isMobileMenuOpen}>
          <LinkBox onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <MobileStyledLink to="/">New</MobileStyledLink>
          </LinkBox>
          <LinkBox onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <MobileStyledLink to="/notaryBook">
              Records
            </MobileStyledLink>
          </LinkBox>
          <LinkBox onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <MobileStyledLink to="/sessionRequests">
              Session Requests
            </MobileStyledLink>
          </LinkBox>
          <MobileConnectAuthButton onClick={handleLogout}>
            Log out
          </MobileConnectAuthButton>
        </MobileMenu>
      )}
      {isLogoutPopupVisible && (
        <PopupOverlay onClick={closeLogoutPopup}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            <PopupHeader>Are you sure you want to logout?</PopupHeader>
            <ButtonGroup>
              <DynamicButton onClick={closeLogoutPopup}>Cancel</DynamicButton>
              <DynamicButton onClick={() => onLogout()} variant="save">
                {isLogoutLoading ? <LoadingSpinner /> : "Yes"}
              </DynamicButton>
            </ButtonGroup>
          </PopupContainer>
        </PopupOverlay>
      )}
    </StyledHeader>
  );
};

export default Sidebar;

const StyledHeader = styled.header<{ isExpanded: boolean }>`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.isExpanded ? "11%" : "6%")};
  min-height: 100vh;
  padding: 10px 0;
  z-index: 100;
  position: fixed;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  @media (max-width: 768px) {
    width: 100%;
    min-height: auto;
    position: relative;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesktopMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  margin-top: 7vh;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
`;

const StyledLink = styled(Link) <{ isActive?: boolean; isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  justify-content: ${(props) => (props.isExpanded ? "flex-start" : "center")};
  color: ${(props) => (props.isActive ? "white" : props.theme.colors.primary)};
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) =>
    props.isActive ? props.theme.colors.primary : "none"};
  border-top-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
  border-bottom-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
  &:hover {
    background: #ebebeb;
    // border: 1px solid ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    border-top-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
    border-bottom-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
    svg {
      path {
        fill: ${(props) =>
    props.isExpanded ? props.theme.colors.primary : "white"};
      }
    }
  }
  span {
    display: ${(props) => (props.isExpanded ? "inline" : "none")};
  }
`;

const RecordStyledLink = styled(StyledLink) `
          margin-top: 8vh;
`
const StyledLinkRequest = styled(Link) <{
  isActive?: boolean;
  isExpanded?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  justify-content: ${(props) => (props.isExpanded ? "flex-start" : "center")};
  color: ${(props) => (props.isActive ? "white" : props.theme.colors.primary)};
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) =>
    props.isActive ? props.theme.colors.primary : "none"};
  border-top-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
  border-bottom-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
  &:hover {
    background: #ebebeb;
    // border: 1px solid ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    border-top-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
    border-bottom-right-radius: ${(props) =>
    props.isActive && props.isExpanded ? "25px" : "10px"};
    svg {
      path {
        fill: ${(props) =>
    props.isExpanded ? props.theme.colors.primary : "white"};
      }
    }
  }
  span {
    display: ${(props) => (props.isExpanded ? "inline" : "none")};
  }
`;

const StyledLinkBox = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  justify-content: ${(props) => (props.isExpanded ? "flex-start" : "center")};
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background: #ebebeb;
    // border: 1px solid ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.primary};
    svg {
      path {
        fill: ${(props) =>
    props.isExpanded ? props.theme.colors.primary : "white"};
      }
    }
  }
  span {
    display: ${(props) => (props.isExpanded ? "inline" : "none")};
  }
`;

const MobileStyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  color: black;
  text-decoration: none;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background: #ebebeb;
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const LinkBox = styled.div`
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid rgba(233, 246, 208, 0.2);
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background: var(--dark, #13160b);
  z-index: 1000;
  padding: 20px 24px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
`;

const MobileConnectAuthButton = styled(Button)`
  margin-top: auto;
  width: 100% !important;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupHeader = styled.h2`
  text-align: center;
  padding-bottom: 13px;
  border-bottom: 1px solid black;
  margin-right: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
  margin-left: auto;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DynamicButton = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const NotificationBadge = styled.div`
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 22px;
  transform: translate(50%, -50%);
`;
