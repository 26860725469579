import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  AppDispatch,
  uploadDoc,
  uploadPDF,
  getDocumentWithFile,
} from "../../store";
import { ReactComponent as UploadIcon } from "../../assets/bytesize_upload.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";

type UploadedFile = {
  formData: FormData;
  originalName: string;
  date: string;
};

type AddDocPopupProps = {
  open: boolean;
  onClose: () => void;
  addDocument: (doc: any) => void;
  sessionId: string;
};

const AddDocPopup: React.FC<AddDocPopupProps> = ({
  open,
  onClose,
  addDocument,
  sessionId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const maxFiles = 40;
  const [dragging, setDragging] = useState(false);
  const [loadingCreateSession, setLoadingCreateSession] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) setUploadedFiles([]);
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    const files = Array.from(event.dataTransfer.files) as File[];
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return ["doc", "docx", "pdf", "txt", "rtf", "odt"].includes(
        fileExtension!
      );
    });

    if (validFiles.length !== files.length) {
      toast.error("Only document files are allowed.", {
        autoClose: 5000,
      });
    }

    if (uploadedFiles.length + validFiles.length <= maxFiles) {
      const formDataArray = validFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("date", getTodayDate());
        formData.append("originalName", file.name);

        return {
          formData,
          originalName: file.name,
          date: getTodayDate(),
        };
      });

      setUploadedFiles((prevFiles) => [...prevFiles, ...formDataArray]);
    } else {
      toast.error(`You can upload a maximum of ${maxFiles} files.`, {
        autoClose: 5000,
      });
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return ["doc", "docx", "pdf", "txt", "rtf", "odt"].includes(
          fileExtension!
        );
      });

      if (validFiles.length !== files.length) {
        toast.error("Only document files are allowed.", {
          autoClose: 5000,
        });
      }

      if (uploadedFiles.length + validFiles.length <= maxFiles) {
        const formDataArray = validFiles.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("date", getTodayDate());
          formData.append("originalName", file.name);

          return {
            formData,
            originalName: file.name,
            date: getTodayDate(),
          };
        });

        setUploadedFiles((prevFiles) => [...prevFiles, ...formDataArray]);
      } else {
        toast.error(`You can upload a maximum of ${maxFiles} files.`, {
          autoClose: 5000,
        });
      }
    }
  };

  const handleFileDelete = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleAddingDoc = async (e: React.FormEvent) => {
    e.preventDefault();
    if (uploadedFiles.length === 0) return;
    setLoadingCreateSession(true);
    try {
      let documentsResponse: any[] = [];
      for (let i = 0; i < uploadedFiles.length; i++) {
        const { formData, originalName } = uploadedFiles[i]; 
        const file = formData.get("file") as File;
        const fileName = i === 0 ? `document.pdf` : `document${i}.pdf`;

        const uploadDocResponse = await dispatch(
          uploadDoc({
            sessionId: sessionId,
            fileName,
          })
        );
        console.log("uploadDocResponse==>", uploadDocResponse)
        if (uploadDocResponse.meta.requestStatus === "fulfilled") {
          const uploadedDoc = uploadDocResponse.payload;

          const uploadPdfResponse = await dispatch(
            uploadPDF({
              url: uploadedDoc.url,
              file,
            })
          );
          console.log("uploadPdfResponse===>", uploadPdfResponse)

          if (uploadPdfResponse.payload?.etag) {
            const documentWithFileResponse = await dispatch(
              getDocumentWithFile({
                fileName,
                sessionId: sessionId,
                name: originalName,
                hash: uploadPdfResponse.payload.etag,
              })
            );
            console.log("documentWithFileResponse===>", documentWithFileResponse)
            if (documentWithFileResponse.meta.requestStatus === "fulfilled") {
              documentsResponse.push(documentWithFileResponse.payload);

            } else {
              throw new Error("Error in document");
            }
          } else {
            throw new Error("ETag not found");
          }
        } else {
          throw new Error("Error while uploading");
        }
      }

      const invalidDocuments = documentsResponse.some(
        (doc) => doc.fileCheckResult && !doc.fileCheckResult.ok
      );
      if (invalidDocuments) {
        throw new Error("Invalid document");
      }

      setLoadingCreateSession(false);
      addDocument([...documentsResponse])
      toast.success("Document added", { autoClose: 5000 });
      onClose();
    } catch (err) {
      setLoadingCreateSession(false);
      console.error(err);
      toast.error("Failed to add the document.", {
        autoClose: 5000,
      });
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  if (!open) return null;
  return (
    <Overlay>
      <PopupContainer>
        <PopupContent>
          <Form onSubmit={handleAddingDoc}>
            <DragDropArea
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={() => setDragging(true)}
              onDragLeave={() => setDragging(false)}
              dragging={dragging}
            >
              <UploadIcon />
              <DragDropText>Drag files here</DragDropText>
              <OrText>OR</OrText>
              <BrowseText
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                <BrowseLink href="#">Browse your computer</BrowseLink>
              </BrowseText>
              <HiddenFileInput
                id="fileInput"
                type="file"
                onChange={handleFileInputChange}
                multiple
              />
            </DragDropArea>
            <FileLimitText>
              {uploadedFiles.length} / {maxFiles}
            </FileLimitText>
            {uploadedFiles.length > 0 && (
              <FileTable>
                <thead>
                  <tr>
                    <th>Document Name</th>
                    <th>Document Date</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedFiles.map((fileData: UploadedFile, index) => {
                    const { originalName, date } = fileData;

                    return (
                      <tr key={index}>
                        <td>
                          <Input type="text" defaultValue={originalName} />
                        </td>
                        <td>
                          <Input type="date" defaultValue={date} />
                        </td>
                        <td>
                          <DeleteButton onClick={() => handleFileDelete(index)}>
                            <TrashIcon />
                          </DeleteButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </FileTable>
            )}
            <ButtonGroup>
              <Button variant="cancel" onClick={onClose}>
                Close
              </Button>
              <Button type="submit" isDisabled={uploadedFiles.length === 0}>
                {loadingCreateSession ? <LoadingSpinner /> : "Finish"}
              </Button>
            </ButtonGroup>
          </Form>
        </PopupContent>
      </PopupContainer>
    </Overlay>
  );
};

export default AddDocPopup;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const PopupContainer = styled.div`
  background: white;
  width: 600px;
  max-width: 90%;
  max-height: 80vh;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const PopupContent = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  flex-grow: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DragDropArea = styled.div<{ dragging: boolean }>`
  border: 2px dashed ${({ dragging }) => (dragging ? "#000" : "#ccc")};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background: #f9f9f9;
`;

const DragDropText = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

const OrText = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

const BrowseText = styled.div`
  font-size: 14px;
  background: #f1f1f1;
  padding: 15px 25px;
  border-radius: 8px;
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
`;

const BrowseLink = styled.a`
  color: #007bff;
  text-decoration: none;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileLimitText = styled.div`
  font-size: 12px;
`;

const FileTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    padding: 10px;
    text-align: left;
  }

  th {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    font-size: 13px;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button<{ variant?: string; isDisabled?: boolean }>`
  padding: 8px 16px;
  border: none;
  background-color: ${(props) => (props.isDisabled ? "#F1F1F1" : "#007bff")};
  color: ${(props) => (props.isDisabled ? "#CCCBCB" : "white")};
  border-radius: 8px;
  cursor: pointer;
  ${({ variant }) =>
    variant === "save" &&
    `
    background-color: #007bff;
    color: white;
  `}

  ${({ variant }) =>
    variant === "cancel" &&
    `
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
  `}
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
